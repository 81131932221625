declare global {
  interface Window {
    grecaptcha: {
      ready(callback: () => void): void;
      execute(token: string, body: { action: string }): Promise<string>;
    };
  }
}

export function challenge() {
  return new Promise<string>((resolve, reject) =>
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LexTAAqAAAAAE75WZ5NEx8Lje0_LegIFcmZhjGN", {
          action: "submit",
        })
        .then(resolve)
        .catch(reject);
    }),
  );
}

export * as Recaptcha from "./recaptcha.client";
