import { ulid } from "ulidx";
import { client } from "../client";
import { Recaptcha } from "../core/recaptcha/recaptcha.client";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { instance, minLength, object, pipe, regex, string } from "valibot";
import { useMemo, useState } from "react";

export default function Home() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [uploadedPhotos, setUploadedPhotos] = useState(0);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const percentage = useMemo(() => {
    const value = Math.floor((uploadedPhotos / totalPhotos) * 100);

    return isNaN(value) ? 0 : value;
  }, [uploadedPhotos, totalPhotos]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: valibotResolver(
      object({
        name: pipe(
          string("Name is required"),
          minLength(1, "Name is required"),
          regex(/^[a-zA-Z\s]+$/u, "Name must only contain letters and spaces"),
        ),
        photos: instance(FileList, "At least one photo is required."),
      }),
    ),
  });

  return (
    <div className="py-2 px-3 pb-24">
      <div className="max-w-md mx-auto">
        <div className="space-y-6">
          <div className="text-center">
            <h1 className="text-3xl font-bold pb-2">
              The White Wedding Photos
            </h1>
          </div>
          <img
            className="aspect-video w-full rounded  object-cover"
            src="/dustinandjenna-cover.webp"
          />
          <div className="text-center">
            <h1 className="text-2xl font-bold pb-2">Upload Photos</h1>
            <p className="text-muted-foreground">
              Upload the photos you took at our wedding so we can all share the
              memories!
            </p>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(async (values) => {
            setIsLoading(true);
            setErrorMessage("");
            setSuccessMessage("");

            let tempSuccessMessage: string | undefined = undefined;
            let tempErrorMessage: string | undefined = undefined;

            try {
              const token = await Recaptcha.challenge();

              const name = values.name;
              const photos = Array.from(values.photos as FileList);

              setTotalPhotos(photos.length);

              const mappedPhotos = photos.map((photo) => ({
                id: ulid(),
                file: photo,
              }));

              const result = await client.api.uploadUrls.$post({
                json: {
                  name,
                  token,
                  photos: mappedPhotos.map((photo) => ({
                    id: photo.id,
                    contentLength: photo.file.size,
                    contentType: photo.file.type,
                  })),
                },
              });

              if (result.ok) {
                const json = await result.json();

                const failures: string[] = [];

                await Promise.all(
                  json.urls.map(async (url) => {
                    const response = await fetch(url.url, {
                      method: "PUT",
                      body: mappedPhotos.find(
                        (photo) => photo.id === url.clientId,
                      )?.file,
                    });

                    console.log(response);

                    if (response.ok) {
                      setUploadedPhotos((prev) => prev + 1);
                    } else {
                      failures.push(url.clientId);
                    }
                  }),
                );

                if (failures.length > 0) {
                  tempErrorMessage =
                    "Some photos failed to upload. Please try again by pushing the upload button.";
                } else {
                  reset();
                  setSuccessMessage(
                    "Photos uploaded successfully! It may take a few minutes for them to appear.",
                  );
                  setTotalPhotos(0);
                }
              } else {
                const json = await result.json();

                console.log("ERROR:", json);

                tempErrorMessage = json.message as string;
              }
            } catch (error) {
              console.error(error);

              if (!tempErrorMessage) {
                tempErrorMessage = "An error occurred. Please try again.";
              }
            }

            setUploadedPhotos(0);
            setErrorMessage(tempErrorMessage);
            setIsLoading(false);
          })}
          className="space-y-4 pb-4"
        >
          <div>
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor="name"
            >
              Your Name
            </label>
            <div className="flex items-center space-x-2">
              <input
                className="flex w-full rounded-md border border-gray-300 h-10 text-sm text-gray-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 disabled:cursor-not-allowed disabled:opacity-50 px-4"
                id="name"
                required
                autoComplete="full-name"
                disabled={isLoading}
                minLength={1}
                maxLength={50}
                {...register("name")}
              />
            </div>
            {errors.name && (
              <p className="text-red-700">{errors.name.message as string}</p>
            )}
          </div>
          <div>
            <label
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor="photos"
            >
              Select Photos
            </label>
            <div className="flex items-center space-x-2">
              <input
                className="flex w-full rounded-md border border-gray-300 h-10 text-sm file:h-10 file:border-0 file:bg-gray-900 file:text-sm file:font-medium file:text-gray-100 file:px-4 file:rounded-l file:rounded-r-none placeholder:text-gray-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 disabled:cursor-not-allowed disabled:opacity-50"
                id="photos"
                type="file"
                accept="image/*"
                multiple
                required
                disabled={isLoading}
                minLength={1}
                {...register("photos")}
              />
            </div>
            {errors.photos && (
              <p className="text-red-700">{errors.photos.message as string}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="w-full h-10 bg-gray-900 text-center text-gray-100 py-2 disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 rounded"
              disabled={isLoading}
            >
              Upload
            </button>
          </div>
          {isLoading && (
            <div className="bg-gray-100 rounded-full h-6 w-full relative overflow-hidden">
              <div
                className="bg-gray-300 h-full absolute left-0 top-0 rounded-full transition-all duration-300"
                style={{ width: `${percentage}%` }}
              />
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-800 font-medium">
                {percentage}%
              </div>
            </div>
          )}
          {errorMessage && <p className="text-red-700">{errorMessage}</p>}
          {successMessage && <p className="text-green-700">{successMessage}</p>}
        </form>
        <a
          href={isLoading ? "#" : "/photosv2"}
          className="w-full block h-10 border border-gray-900 text-center text-gray-900 py-2 aria-disabled:cursor-not-allowed aria-disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 rounded"
          aria-disabled={isLoading}
        >
          View Photos
        </a>
      </div>
    </div>
  );
}
